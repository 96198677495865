import 'babel-polyfill';
import svg4everybody from 'svg4everybody';
import Pristine from 'pristinejs';
import Swiper from 'swiper';
import Cookies from '../../node_modules/js-cookie/dist/js.cookie';
import '../../node_modules/video.js/dist/video';



window.Pristine = Pristine;
window.Swiper = Swiper;
window.Cookies = Cookies;
window.W = window;
window.D = document;
window.H = document.querySelector('html');
window.B = document.querySelector('body');


svg4everybody();
